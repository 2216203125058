*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.map{
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.map-wrapper{
    position: relative;
    top: 0; left: 0;
    height: 80%;
    width: 80%;
    z-index: 12;
    cursor: pointer;
}