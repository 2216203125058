@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

$white-color: #EBE4D6;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}
.info-screen{
    background: linear-gradient(19.5deg, #2E2D33 2.51%, #2E2D33 2.52%, #2E2D33 11.67%, rgba(46, 45, 51, 0) 85%);
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    padding: 50px;
    cursor: pointer;
    p{
        color: $white-color;
        padding: 10px 0;
    }
}
.close{
    cursor: pointer;
    position: absolute;
    top: 0; right: 0;
    padding: 20px 50px 0 0;
}
.information{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}


