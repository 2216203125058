*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}
.video{
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}